<template>
  <section>
    <section
      v-if="noResult === true"
      class="noResult"
    >
      <img
        src="../../assets/icons/fail.svg"
        alt="fail"
      >
      <span>Sorry, No Result Found</span>
    </section>
    <section v-if="noResult === false">
      <section class="top">
        <div>
          <p class="tt_title">
            TimeTable
          </p>
          <p class="tt_subtitle">
            Updating Timetable For Class {{ tt.class }}
          </p>
        </div>

        <div class="top-btn">
          <VBtn
            round
            large
            class="shadow"
            color="white"
            @click="toUpdate = true"
          >
            Update
          </VBtn>
        </div>
      </section>
      <section class="content">
        <div class="grid">
          <VForm
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <VCard class="shadow tt_card">
              <p class="tt_main_title pl-2">
                Monday
              </p>
              <p class="tt_main_subtitle pl-2">
                Class {{ tt.class }}
              </p>
              <div>
                <VSelect
                  v-model="tt.day.monday.p1"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-1"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.monday.p2"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-2"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.monday.p3"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-3"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.monday.p4"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-4"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.monday.p5"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-5"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.monday.p6"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-6"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.monday.p7"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-7"
                  flat
                  solo
                />
              </div>
            </VCard>
          </VForm>
          <VForm
            ref="form1"
            v-model="valid"
            lazy-validation
          >
            <VCard class="shadow tt_card">
              <p class="tt_main_title pl-2">
                Tuesday
              </p>
              <p class="tt_main_subtitle pl-2">
                Class {{ tt.class }}
              </p>
              <div>
                <VSelect
                  v-model="tt.day.tuesday.p1"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-1"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.tuesday.p2"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-2"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.tuesday.p3"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-3"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.tuesday.p4"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-4"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.tuesday.p5"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-5"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.tuesday.p6"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-6"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.tuesday.p7"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-7"
                  flat
                  solo
                />
              </div>
            </VCard>
          </VForm>
          <VForm
            ref="form2"
            v-model="valid"
            lazy-validation
          >
            <VCard class="shadow tt_card">
              <p class="tt_main_title pl-2">
                Wednesday
              </p>
              <p class="tt_main_subtitle pl-2">
                Class {{ tt.class }}
              </p>
              <div>
                <VSelect
                  v-model="tt.day.wednesday.p1"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-1"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.wednesday.p2"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-2"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.wednesday.p3"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-3"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.wednesday.p4"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-4"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.wednesday.p5"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-5"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.wednesday.p6"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-6"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.wednesday.p7"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-7"
                  flat
                  solo
                />
              </div>
            </VCard>
          </VForm>
          <VForm
            ref="form3"
            v-model="valid"
            lazy-validation
          >
            <VCard class="shadow tt_card">
              <p class="tt_main_title pl-2">
                Thursday
              </p>
              <p class="tt_main_subtitle pl-2">
                Class {{ tt.class }}
              </p>
              <div>
                <VSelect
                  v-model="tt.day.thursday.p1"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-1"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.thursday.p2"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-2"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.thursday.p3"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-3"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.thursday.p4"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-4"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.thursday.p5"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-5"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.thursday.p6"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-6"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.thursday.p7"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-7"
                  flat
                  solo
                />
              </div>
            </VCard>
          </VForm>
          <VForm
            ref="form4"
            v-model="valid"
            lazy-validation
          >
            <VCard class="shadow tt_card">
              <p class="tt_main_title pl-2">
                Friday
              </p>
              <p class="tt_main_subtitle pl-2">
                Class {{ tt.class }}
              </p>
              <div>
                <VSelect
                  v-model="tt.day.friday.p1"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-1"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.friday.p2"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-2"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.friday.p3"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-3"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.friday.p4"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-4"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.friday.p5"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-5"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.friday.p6"
                  :rules="[rules.required]"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-6"
                  flat
                  solo
                />
                <VSelect
                  v-model="tt.day.friday.p7"
                  :items="selectSubject"
                  :hide-details="true"
                  placeholder="Select Subject"
                  class="tt_main_txt"
                  prepend-icon="mdi-numeric-7"
                  flat
                  solo
                />
              </div>
            </VCard>
          </VForm>
        </div>
      </section>
    </section>
    <VDialog
      v-model="isLoading"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
    >
      <VCard
        v-if="loadStatus === 'loading'"
        class="notification_fs elevation-20"
      >
        <p>Loading</p>
        <span>Fetching Data, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="white"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_fs elevation-20"
      >
        <p>Internet Issue</p>
        <span>Please Try Again Later</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_fs elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
    <VDialog
      v-model="toUpdate"
      width="300"
      persistent
    >
      <ConfirmAction
        @confirm="confirmUpdate()"
        @close="toUpdate = false"
      />
    </VDialog>
    <VDialog
      v-model="isUpdating"
      width="300"
      persistent
    >
      <VCard
        v-if="loadStatus === 'updating'"
        class="notification_card elevation-20"
      >
        <p>Processing</p>
        <span>Updating TimeTable, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'updated'"
        class="notification_card elevation-20"
      >
        <p>Updated</p>
        <span>TimeTable Updated For Class {{ tt.class }}</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_card elevation-20"
      >
        <p>Internet Issue</p>
        <span>Retrying In {{ retry }} Seconds</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_card elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../../conf/api';
import ConfirmAction from '../../components/confirmAction.vue';

export default {
  components: {
    ConfirmAction,
  },

  data: () => ({
    isLoading: true,
    isUpdating: false,
    loadStatus: '',
    retry: 0,
    noResult: false,
    valid: true,
    toUpdate: false,
    stdClass: '',
    selectSubject: [],
    tt: {
      class: '?',
      day: {
        monday: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: null,
        },
        tuesday: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: null,
        },
        wednesday: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: null,
        },
        thursday: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: null,
        },
        friday: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: null,
        },
      },
    },
    rules: {
      required: (val) => {
        if (val == null) {
          return 'Required';
        }
        if (val === '') {
          return 'Required';
        }
        return true;
      },
    },
  }),

  async beforeMount() {
    try {
      this.loadStatus = 'loading';
      // GET SUBJECTS
      await axios.get(`${api}/settings/subjects`).then((res) => {
        this.selectSubject = res.data.data;
      });
      // GET TT DATA
      const { id } = this.$route.params;
      await axios.get(`${api}/timetable/${id}`)
        .then((res) => {
          this.isLoading = false;
          if (res.data.status === 'success') {
            this.tt = res.data.data;
          } else {
            this.isLoading = false;
            this.noResult = true;
          }
        });
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push('/timetable');
        }, 5000);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },

  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    closeUpdate() { },
    confirmUpdate() {
      if (
        this.$refs.form.validate()
        && this.$refs.form1.validate()
        && this.$refs.form2.validate()
        && this.$refs.form3.validate()
        && this.$refs.form4.validate()
      ) {
        const ttSchema = {
          day: this.tt.day,
        };
        this.isUpdating = true;
        this.loadStatus = 'updating';
        const { id } = this.$route.params;
        axios.post(`${api}/timetable/update/${id}`, ttSchema).then(() => {
          this.loadStatus = 'updated';
          setTimeout(() => {
            this.isUpdating = false;
            this.$router.push(`/timetable/${id}`);
          }, 2500);
        })
          .catch((e) => {
            if (e.code === 'auth/network-request-failed') {
              this.loadStatus = 'netfail';
              this.retry = 10;
              setInterval(() => {
                this.retry -= 1;
              }, 1000);
              setTimeout(() => {
                window.location.reload();
              }, 9500);
            } else {
              this.loadStatus = 'fail';
            }
          });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/TimeTable/update';
</style>
